// Categories
export const ASSET_REAL_ESTATE_CATEGORY = {
  id: 1,
  name: 'Property',
  icon: 'sl-custom-house-2',
};
export const ASSET_VEHICLE = {
  id: 2,
  name: 'Vehicle',
  icon: 'sl-custom-car-3',
};
export const ASSET_INVESTMENT = {
  id: 3,
  name: 'Investment',
  icon: 'sl-custom-business-chart-2',
};
export const ASSET_CASH_SAVINGS = {
  id: 4,
  name: 'Savings & Cash',
  icon: 'sl-custom-piggy-bank',
}; // myCrm name is 'Cash & Savings' but we flipped
export const ASSET_OTHER = { id: 5, name: 'Other', icon: 'sl-custom-edit-3' };
export const ASSET_CONTENTS = {
  id: 6,
  name: 'Home contents',
  icon: 'sl-custom-sofa-1',
};
export const ASSET_BUSINESS = {
  id: 7,
  name: 'Business',
  icon: 'sl-custom-percent-up',
};
export const ASSET_SUPER = {
  id: 8,
  name: 'Superannuation',
  icon: 'sl-custom-dollar-bag',
};

export const ASSET_CATEGORIES = [
  ASSET_REAL_ESTATE_CATEGORY,
  ASSET_CASH_SAVINGS,
  ASSET_SUPER,
  ASSET_CONTENTS,
  ASSET_VEHICLE,
  ASSET_INVESTMENT,
  ASSET_BUSINESS,
  ASSET_OTHER,
];

// Types
export const ASSET_REAL_ESTATE = { id: 1 };
export const ASSET_BOAT = { id: 2, name: 'Boat' };
export const ASSET_BONDS = { id: 3, name: 'Bonds' };
export const ASSET_CASH = { id: 4, name: 'Cash' };
export const ASSET_CASH_MANAGEMENT = { id: 5, name: 'Cash Management' };
export const ASSET_CHARGE_OVER_CASH = { id: 6, name: 'Charge Over Cash' };
export const ASSET_CHEQUE_ACCOUNT = { id: 7, name: 'Cheque Account' };
export const ASSET_COLLECTIONS = { id: 8, name: 'Collections' };
export const ASSET_DEBENTURE_CHARGE = { id: 9, name: 'Debenture Charge' };
export const ASSET_FURNITURE = { id: 10, name: 'Furniture' };
export const ASSET_GIFTS = { id: 11, name: 'Gifts' };
export const ASSET_GOODWILL = { id: 12, name: 'Goodwill' };
export const ASSET_GUARANTEE = { id: 13, name: 'Guarantee' };
export const ASSET_HOME_CONTENTS = { id: 14, name: 'Home Contents' };
export const ASSET_SAVINGS = { id: 15, name: 'Savings' };
export const ASSET_LIFE_INSURANCE = { id: 16, name: 'Life Insurance' };
export const ASSET_MANAGED_FUNDS = { id: 17, name: 'Managed Funds' };
export const ASSET_MOTOR = { id: 18, name: 'Motor Vehicle' };
export const ASSET_OTHER_OTHER = { id: 19, name: 'Other' };
export const ASSET_OTHER_DEPOSIT = { id: 20, name: 'Other Deposit' };
export const ASSET_PENSION_ACCOUNT = { id: 21, name: 'Pension Account' };
export const ASSET_EQUITY = { id: 22, name: 'Equity' };
export const ASSET_RECEIVABLES = { id: 23, name: 'Receivables' };
export const ASSET_SAVINGS_ACCOUNT = { id: 24, name: 'Savings Account' };
export const ASSET_SHARES = { id: 25, name: 'Shares' };
export const ASSET_STOCK_AND_MACHINERY = {
  id: 26,
  name: 'Stock and Machinery',
};
export const ASSET_SUPER_SUPER = { id: 27, name: 'Superannuation' };
export const ASSET_TERM_DEPOSIT = { id: 28, name: 'Term Deposit' };
export const ASSET_TIME_SHARE = { id: 29, name: 'Time Share' };
export const ASSET_TOOLS = { id: 30, name: 'Tools of Trade' };
export const ASSET_DEPOSIT_PAID = { id: 31, name: 'Deposit Paid' };
export const ASSET_SUPER_KIWISAVER = {
  id: 32,
  name: 'Kiwisaver',
  availableCountries: ['NZ'],
};

export const ASSET_TYPES_PER_CATEGORY = {
  [ASSET_REAL_ESTATE_CATEGORY.id]: [ASSET_REAL_ESTATE],
  [ASSET_VEHICLE.id]: [ASSET_MOTOR, ASSET_BOAT],
  [ASSET_INVESTMENT.id]: [
    ASSET_BONDS,
    ASSET_DEBENTURE_CHARGE,
    ASSET_SAVINGS,
    ASSET_MANAGED_FUNDS,
    ASSET_SHARES,
  ],
  [ASSET_CASH_SAVINGS.id]: [
    ASSET_CASH,
    ASSET_CASH_MANAGEMENT,
    ASSET_CHEQUE_ACCOUNT,
    ASSET_PENSION_ACCOUNT,
    ASSET_SAVINGS_ACCOUNT,
    ASSET_TERM_DEPOSIT,
    ASSET_DEPOSIT_PAID,
    ASSET_OTHER_DEPOSIT,
  ],
  [ASSET_OTHER.id]: [
    ASSET_CHARGE_OVER_CASH,
    ASSET_COLLECTIONS,
    ASSET_GIFTS,
    ASSET_GUARANTEE,
    ASSET_LIFE_INSURANCE,
    ASSET_TIME_SHARE,
    ASSET_OTHER_OTHER,
  ],
  [ASSET_CONTENTS.id]: [ASSET_FURNITURE, ASSET_HOME_CONTENTS],
  [ASSET_BUSINESS.id]: [
    ASSET_GOODWILL,
    ASSET_EQUITY,
    ASSET_RECEIVABLES,
    ASSET_STOCK_AND_MACHINERY,
    ASSET_TOOLS,
  ],
  [ASSET_SUPER.id]: [ASSET_SUPER_SUPER, ASSET_SUPER_KIWISAVER],
};
