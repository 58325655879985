import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';

import { REQUIRED, GREATER_THAN_ZERO } from 'constants/validators';

import {
  ASSET_MOTOR,
  ASSET_REAL_ESTATE_CATEGORY,
} from 'shared/constants/myCRMTypes/assets';
import {
  assetTypeOptions,
  ASSET_CATEGORY_OPTIONS,
  assetCategoryWithDefaultType,
  ASSET_YEAR_OPTIONS,
} from 'constants/options';
import manageQuestions, {
  manageQuestionsPropTypes,
} from 'hocs/manageQuestions';

import Form from 'components/Form/Form';
import Question from 'components/Question/Question';
import Input from 'components/Input/Input';
import Selection from 'components/Selection/Selection';
import Button from 'components/Button/Button';
import CurrencyInput from 'components/CurrencyInput/CurrencyInput';
import RadioButtonList from 'components/ButtonList/RadioButtonList';
import Questions from 'lib/Questions';
import { findOption } from 'lib/optionHelpers';

const messages = defineMessages({
  categoryRadio: {
    id: 'AssetForm.categoryRadio',
    defaultMessage: 'What type of asset?',
  },
  categorySelect: {
    id: 'AssetForm.categorySelect',
    defaultMessage: 'Asset type',
  },
  ownership: {
    id: 'AssetForm.ownership',
    defaultMessage: 'Ownership',
  },
  value: {
    id: 'AssetForm.value',
    defaultMessage: 'Value',
  },
  rent: {
    id: 'AssetForm.rent',
    defaultMessage: 'Rent',
  },
  address: {
    id: 'AssetForm.address',
    defaultMessage: 'Address',
  },
  make: {
    id: 'AssetForm.make',
    defaultMessage: 'Make',
  },
  year: {
    id: 'AssetForm.year',
    defaultMessage: 'Year',
  },
  bank: {
    id: 'AssetForm.bank',
    defaultMessage: 'Bank',
  },
  description: {
    id: 'AssetForm.description',
    defaultMessage: 'Description (optional)',
  },
  save: {
    id: 'AssetForm.save',
    defaultMessage: 'Save',
  },
  saveAndAddAnother: {
    id: 'AssetForm.saveAndAddAnother',
    defaultMessage: 'Save and add another',
  },
  remove: {
    id: 'AssetForm.remove',
    defaultMessage: 'Remove',
  },
  confirm: {
    id: 'AssetForm.confirm',
    defaultMessage: 'Confirm',
  },
});

const propsTransformForQuestion = (props) => ({
  ...props.working,
  clientIdOwnershipOptions: props.clientIdOwnershipOptions,
});

const questionSet = (props) => {
  const questions = new Questions();
  const { category, type, clientIdOwnershipOptions } = props;
  questions.addBranch(['category', REQUIRED]);
  if (category) {
    if (!assetCategoryWithDefaultType(category)) {
      questions.addBranch(['type', REQUIRED]);
    }
    if (type || assetCategoryWithDefaultType(category)) {
      if (clientIdOwnershipOptions.length > 1) {
        questions.addBranch(['clientIds', REQUIRED]);
      }

      if (type === ASSET_MOTOR.id) {
        questions.add(['make', REQUIRED], ['year', REQUIRED]);
      }
      questions.add(['value', GREATER_THAN_ZERO, REQUIRED], 'description');
      questions.add('saveButton', 'saveAndAddAnotherButton');
    }
  }

  return questions.arrayOfQuestions();
};

export class DisconnectedAssetForm extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    working: PropTypes.object.isRequired,
    save: PropTypes.func.isRequired,
    saveAndAddAnother: PropTypes.func.isRequired,
    setAssetClientIds: PropTypes.func.isRequired,
    setAssetMake: PropTypes.func.isRequired,
    setAssetYear: PropTypes.func.isRequired,
    setAssetValue: PropTypes.func.isRequired,
    setAssetDescription: PropTypes.func.isRequired,
    setAssetError: PropTypes.func.isRequired,
    clientIdOwnershipOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    ...manageQuestionsPropTypes,
  };

  static questionsToAsk = questionSet;
  static revealMethod = 'chunks';
  static revealOverBranch = false;

  componentDidMount() {
    const {
      working,
      setAssetClientIds,
      clientIdOwnershipOptions,
      history,
      setAssetCategory,
    } = this.props;
    if (!working.id && clientIdOwnershipOptions.length === 1) {
      setAssetClientIds(clientIdOwnershipOptions[0].value);
    }

    if (history && history.location.query.type) {
      setAssetCategory(history.location.query.type);
    }
  }

  onSubmit = () => {
    const { save, working } = this.props;
    save(working);
  };

  renderTypeQuestion = () => {
    const {
      working: { category, type },
      questions,
      setAssetType,
    } = this.props;
    // This additional logic has to be repeated here because of the unhandled intl.
    const typeOptions = category ? assetTypeOptions(category) : [];
    const categoryName =
      category && findOption(ASSET_CATEGORY_OPTIONS, category).name;
    return (
      <Question
        {...questions.type}
        label={`${categoryName} type`}
        className='fullWidthInput'
      >
        {type ? (
          <Selection action={setAssetType} value={type} items={typeOptions} />
        ) : (
          <RadioButtonList
            action={setAssetType}
            value={type}
            items={typeOptions}
          />
        )}
      </Question>
    );
  };

  render() {
    const {
      isLocked,
      working,
      questions,
      formCompleted,
      setCurrentQuestionTo,
      saveAndAddAnother,
      intl,
      setAssetCategory,
      setAssetClientIds,
      setAssetMake,
      setAssetYear,
      setAssetValue,
      setAssetDescription,
      remove,
      clientIdOwnershipOptions,
    } = this.props;

    const categoryOptions = ASSET_CATEGORY_OPTIONS.filter(
      (c) => parseInt(c.value, 10) !== ASSET_REAL_ESTATE_CATEGORY.id,
    );

    return (
      <Form
        id='assetForm'
        formCompleted={formCompleted}
        onSubmit={this.onSubmit}
        onFocusLost={setCurrentQuestionTo(undefined)}
      >
        <fieldset disabled={isLocked}>
          {!working.category ? (
            <Question
              {...questions.category}
              label={intl.formatMessage(messages.categoryRadio)}
              className='fullWidthInput'
            >
              <RadioButtonList
                action={setAssetCategory}
                value={working.category}
                items={categoryOptions}
              />
            </Question>
          ) : (
            <Question
              {...questions.category}
              label={intl.formatMessage(messages.categorySelect)}
              className='fullWidthInput'
            >
              <Selection
                action={setAssetCategory}
                value={working.category}
                items={categoryOptions}
              />
            </Question>
          )}
          {this.renderTypeQuestion()}
          <Question
            {...questions.clientIds}
            label={intl.formatMessage(messages.ownership)}
            className='fullWidthInput'
          >
            {!working.clientIds || working.clientIds.length === 0 ? (
              <RadioButtonList
                action={setAssetClientIds}
                value={undefined}
                items={clientIdOwnershipOptions}
              />
            ) : (
              <Selection
                action={setAssetClientIds}
                value={working.clientIds && working.clientIds.join()}
                items={clientIdOwnershipOptions}
              />
            )}
          </Question>
          <Question
            {...questions.make}
            label={intl.formatMessage(messages.make)}
            className='fullWidthInput'
          >
            <Input action={setAssetMake} value={working.make} />
          </Question>

          <Question
            {...questions.year}
            label={intl.formatMessage(messages.year)}
            className='fullWidthInput'
          >
            <Selection
              action={setAssetYear}
              value={working.year}
              items={ASSET_YEAR_OPTIONS}
            />
          </Question>
          <Question
            {...questions.value}
            label={intl.formatMessage(messages.value)}
            className='fullWidthInput'
          >
            <CurrencyInput
              action={setAssetValue}
              value={working.value}
              maxLength={11}
            />
          </Question>
          <Question
            {...questions.description}
            label={intl.formatMessage(messages.description)}
            className='fullWidthInput'
          >
            <Input action={setAssetDescription} value={working.description} />
          </Question>
          <Question {...questions.saveButton} className='fullWidthButton'>
            <Button
              theme='linkButton'
              hasErrorMessage={!formCompleted}
              onClick={this.onSubmit}
            >
              {intl.formatMessage(
                working.isOldData ? messages.confirm : messages.save,
              )}
            </Button>
          </Question>
          {working.id ? (
            <Button
              className='brandColor__reverseButton'
              onClick={() => remove(working.id)}
            >
              {intl.formatMessage(messages.remove)}
            </Button>
          ) : (
            <Question
              {...questions.saveAndAddAnotherButton}
              className='fullWidthButton'
            >
              <Button
                theme='linkButton'
                hasErrorMessage={!formCompleted}
                onClick={() => saveAndAddAnother(working)}
              >
                {intl.formatMessage(messages.saveAndAddAnother)}
              </Button>
            </Question>
          )}
          <input className='hidden' type='submit' />
        </fieldset>
      </Form>
    );
  }
}

export default injectIntl(
  manageQuestions(DisconnectedAssetForm, propsTransformForQuestion),
);
