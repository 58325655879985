import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';

import { formatCurrency } from 'lib/intlFormatters';
import { findOption } from 'lib/optionHelpers';
import applyOwnPropsChecker from 'lib/applyOwnPropsChecker';

import {
  ASSET_REAL_ESTATE_CATEGORY,
  ASSET_TYPES_PER_CATEGORY,
} from 'shared/constants/myCRMTypes/assets';

import * as assetSelectors from 'selectors/assetSelectors';
import * as clientSelectors from 'selectors/clientSelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';

import assetActions from 'actions/assetActions';

import applySection from 'hocs/applySection';
import { accordionPropTypes } from 'types/customPropTypes';

import Button from 'components/Button/Button';
import EditableItem from 'components/EditableItem/EditableItem';
import { assetsHint } from 'lib/hintHelper';
import { logEvent, EVENTS } from 'lib/amplitude';
import { ASSET_CATEGORY_OPTIONS } from 'constants/options';
import ApplyAdditionalQuestion from 'components/ApplyAdditionalQuestion/ApplyAdditionalQuestion';
import { ASSETS_SLUG } from 'constants/applyData';
import EditIcon from 'assets/icons/edit-icon.svg';
import SVGInline from 'react-svg-inline';
import styles from './AssetsApply.css';

const messages = defineMessages({
  title: {
    id: 'AssetsApply.assets',
    defaultMessage: 'Assets',
  },
  titleDescription: {
    id: 'AssetsApply.titleDescription',
    defaultMessage: 'Tell us about your assets',
  },
  headerDescription: {
    id: 'AssetsApply.headerDescription',
    defaultMessage: 'Do you have any assets?',
  },
  titleDescriptionCompleted: {
    id: 'AssetsApply.titleDescriptionCompleted',
    defaultMessage:
      '{totalAssets, plural, =0 {You have no assets} other {+{totalAssets, number, currency}}}',
  },
  headerDescriptionCompleted: {
    id: 'AssetsApply.headerDescriptionCompleted',
    defaultMessage:
      '{totalAssets, plural, =0 {You have no assets} other {Your assets are worth {totalAssets, number, currency}}}',
  },
  totalAssets: {
    id: 'AssetsApply.totalAssets',
    defaultMessage: 'Total assets',
  },
  addAsset: {
    id: 'AssetsApply.addAsset',
    defaultMessage: 'Add another asset',
  },
  editAsset: {
    id: 'AssetsApply.editAsset',
    defaultMessage: 'Edit asset',
  },
  hasAssetQuestion: {
    id: 'AssetsApply.hasAssetQuestion',
    defaultMessage: 'Do you have any assets?',
  },
});

export const furtherDecoration = (props) => {
  const {
    intl: { formatMessage },
    totalAssets,
    isCompleted,
    warningMessage,
  } = props;
  const postfix = isCompleted ? 'Completed' : '';
  return {
    title: formatMessage(messages.title),
    titleDescription: formatMessage(messages[`titleDescription${postfix}`], {
      totalAssets,
    }),
    headerDescription: formatMessage(messages[`headerDescription${postfix}`], {
      totalAssets,
    }),
    warningMessage,
  };
};

const nextButtonProps = ({ isCompleted, hasAssets }) => ({
  disabled: !isCompleted && _.isNil(hasAssets),
});

const confirmEntities = (props) => {
  const { confirmAssets, applicationId, primaryContactAssets } = props;

  confirmAssets({ id: applicationId, assets: primaryContactAssets });
};

class AssetsApply extends Component {
  static displayName = 'AssetsApply';
  static propTypes = {
    intl: intlShape.isRequired,
    accordionProps: PropTypes.shape(accordionPropTypes).isRequired,
    primaryApplicantsAssets: PropTypes.arrayOf(PropTypes.object).isRequired,
    primaryApplicantsPartnersAssets: PropTypes.arrayOf(PropTypes.object),
    sharedAssets: PropTypes.arrayOf(PropTypes.object),
    totalAssets: PropTypes.number,
    hasAssets: PropTypes.bool,
    setMetadata: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    warningMessage: PropTypes.string,
    urlPath: PropTypes.string.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    applicationId: PropTypes.number,
    // eslint-disable-next-line react/no-unused-prop-types
    primaryContactAssets: PropTypes.arrayOf(PropTypes.object),
  };

  componentDidMount() {
    this.props.setMetadata({ hasAssets: true });
  }

  static defaultProps = {
    primaryApplicantsPartnersAssets: [],
    sharedAssets: [],
  };

  onHasAssetsClick = (value) => {
    this.props.setMetadata({ hasAssets: value });
  };

  newAssetUrl = (id) => {
    if (id && id.key) {
      return `${this.props.urlPath}/assets/asset/new/?type=${id.key}`;
    }

    return `${this.props.urlPath}/assets/asset/new`;
  };

  editAssetUrl = (id, isRealEstate) =>
    `${this.props.urlPath}/assets/${isRealEstate ? 'property' : 'asset'}/${id}`;

  leftDescription = (singleAsset) => {
    const isRealEstate = singleAsset.category === ASSET_REAL_ESTATE_CATEGORY.id;
    const onConstructDescription = (type) => {
      return `${type} ${
        !_.isEmpty(singleAsset.description)
          ? '- ' + singleAsset.description
          : ''
      } - ${singleAsset.title}`;
    };

    if (singleAsset.make && singleAsset.title) {
      return <>{onConstructDescription(singleAsset.make)}</>;
    }

    if (isRealEstate && singleAsset.address) {
      return onConstructDescription(singleAsset.address.formattedAddress);
    }

    if (!singleAsset.make && singleAsset.type && singleAsset.title) {
      const type = ASSET_TYPES_PER_CATEGORY[singleAsset.category].find(
        (category) => category.id === singleAsset.type,
      );

      if (type) {
        return onConstructDescription(type.name);
      }
    }

    return undefined;
  };

  editableAssetItem = (singleAsset) => {
    const {
      intl,
      accordionProps: { isLocked },
    } = this.props;
    const isRealEstate = singleAsset.category === ASSET_REAL_ESTATE_CATEGORY.id;
    const categoryInfo = findOption(
      ASSET_CATEGORY_OPTIONS,
      singleAsset.category,
    );

    const righLabel = (
      <div className={styles.rightLabel}>
        <div>{formatCurrency(intl)(singleAsset.value)}</div>
        <SVGInline svg={EditIcon} width={20} height={20} />
      </div>
    );

    return (
      <div className={styles.editItem}>
        <EditableItem
          key={`asset-${singleAsset.id}`}
          url={
            (singleAsset.make || singleAsset.type) && singleAsset.title
              ? this.editAssetUrl(singleAsset.id, isRealEstate)
              : this.newAssetUrl(singleAsset)
          }
          leftLabel={categoryInfo.label}
          leftDescription={this.leftDescription(singleAsset)}
          rightLabel={
            singleAsset.value || singleAsset.value === 0 ? righLabel : undefined
          }
          hasWarning={singleAsset.isOldData && !isLocked}
        />
      </div>
    );
  };

  onAddAsset = () => {
    logEvent(EVENTS.ADD_FINANCIALS, { section: ASSETS_SLUG });
  };

  renderMaybeNoAssets() {
    const {
      accordionProps: { isLocked },
      intl: { formatMessage },
      hasAssets,
    } = this.props;

    return (
      <div className={styles.editItem}>
        <ApplyAdditionalQuestion
          id='hasAssets'
          label={formatMessage(messages.hasAssetQuestion)}
          hint={assetsHint}
          value={hasAssets}
          disabled={isLocked}
        />
      </div>
    );
  }

  contructAssets = () => {
    const {
      primaryApplicantsAssets,
      primaryApplicantsPartnersAssets,
      sharedAssets,
    } = this.props;

    const applicantAssets = primaryApplicantsAssets.map((assets) => ({
      ...assets,
      title: 'You',
    }));

    const applicantsPartnersAssets = primaryApplicantsPartnersAssets.map(
      (assets) => ({
        ...assets,
        title: 'Your partner',
      }),
    );

    const sharedAssetsFilter = sharedAssets.map((assets) => ({
      ...assets,
      title: 'You and your partner',
    }));

    const constructAsssets = [
      ...applicantAssets,
      ...applicantsPartnersAssets,
      ...sharedAssetsFilter,
    ];

    const filterExistingAssests = ASSET_CATEGORY_OPTIONS.filter((category) => {
      return (
        !constructAsssets.some((type) => category.key === type.category) &&
        category.key !== ASSET_REAL_ESTATE_CATEGORY.id
      );
    });

    const reconstructAssest = filterExistingAssests.map((assest) => ({
      ...assest,
      category: assest.key,
      value: 0,
    }));

    return [...constructAsssets, ...reconstructAssest];
  };

  renderHasAssets() {
    const constructAsssets = this.contructAssets();

    return (
      <div id='assetsApply'>
        {constructAsssets.length > 0 &&
          constructAsssets.map(this.editableAssetItem)}
      </div>
    );
  }

  render() {
    const {
      totalAssets,
      hasAssets,
      accordionProps: { isLocked },
      intl: { formatMessage },
    } = this.props;

    return (
      <div id='assetApply'>
        {!totalAssets && this.renderMaybeNoAssets()}
        {this.renderHasAssets()}
        {(totalAssets || hasAssets) && !isLocked && (
          <Button
            url={this.newAssetUrl()}
            theme='applyNew'
            icon='sl-custom-car-1'
            onClick={this.onAddAsset}
            className={styles.addAnother}
          >
            {formatMessage(messages.addAsset)}
          </Button>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { id: primaryApplicantId } = ownProps.primaryApplicant;

  const props = {
    assetChartData: assetSelectors.chartData(state),
    primaryApplicantsAssets: assetSelectors.clientsAssets(state)([
      primaryApplicantId,
    ]),
    primaryApplicantsAssetsTotal: assetSelectors.clientsAssetsTotal(state)([
      primaryApplicantId,
    ]),
    clientIdOwnershipOptions: clientSelectors.clientIdOwnershipOptions(state),
    primaryContactAssets: assetSelectors.primaryContactAssets(state),
  };
  if (ownProps.primaryApplicantsPartner) {
    const partnerId = ownProps.primaryApplicantsPartner.id;
    const clientIds = [primaryApplicantId, partnerId];
    props.primaryApplicantsPartnersAssets = assetSelectors.clientsAssets(
      state,
    )([partnerId]);
    props.primaryApplicantsPartnersAssetTotal = assetSelectors.clientsAssetsTotal(
      state,
    )([partnerId]);
    props.sharedAssets = assetSelectors.clientsAssets(state)(clientIds);
    props.sharedAssetsTotal = assetSelectors.clientsAssetsTotal(state)(
      clientIds,
    );
  }
  props.totalAssets = assetSelectors.totalAssets(state);
  props.applicationId = applicationSelectors.getApplicationId(state);
  return props;
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      confirmAssets: assetActions.confirmAssets,
    },
    dispatch,
  );

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    applyOwnPropsChecker,
  )(
    applySection({
      iconName: 'sl-custom-car-1',
      furtherDecoration,
      nextButtonProps,
      confirmEntities,
    })(AssetsApply),
  ),
);
